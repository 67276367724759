@charset "UTF-8";

@font-face {
    font-family: "sbol";
    src: url("fonts/sbol.eot");
    src: url("fonts/sbol.eot?#iefix") format("embedded-opentype"),
    url("fonts/sbol.woff") format("woff"),
    url("fonts/sbol.ttf") format("truetype"),
    url("fonts/sbol.svg#sbol") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[data-icon]:before {
    font-family: "sbol" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="sbol-"]:before,
[class*=" sbol-"]:before {
    font-family: "sbol" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sbol-3-utr:before {
    content: "\61";
}

.sbol-5-utr:before {
    content: "\62";
}

.sbol-cds:before {
    content: "\63";
}

.sbol-composite:before {
    content: "\64";
}

.sbol-hinge-restriction:before {
    content: "\65";
}

.sbol-ncrna:before {
    content: "\66";
}

.sbol-primer-binding-site:before {
    content: "\67";
}

.sbol-other-unknown:before {
    content: "\68";
}

.sbol-terminator:before {
    content: "\69";
}

.sbol-promoter:before {
    content: "\6a";
}

.sbol-plasmid-vector:before {
    content: "\6b";
}
